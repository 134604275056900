import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL
const baseDomain = process.env.VUE_APP_API_URL; 
const baseURL = `${baseDomain}`;
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;
const baseUserURL = process.env.VUE_USER_APP_API_URL;
const bareUserCommon = `${baseUserURL}`;
//START - Avtar Singh - 29-may-2024 : This instance is created for Ticketing  Api which is used in Advance Setup pages (Module components)
const ticketingApiUrl = process.env.VUE_TICKETING_API_URL;
const ticketingApiUrl1 = `${ticketingApiUrl}`;
const DocStorageApiUrl = process.env.VUE_APP_DOCSTORAGE_API;
const DocStorageApiUrl1 = `${DocStorageApiUrl}`;

const CRMApiUrl = process.env.VUE_APP_CRM_API_URL;
const CRMApiUrl1 = `${CRMApiUrl}`;

const baseDomainAsset = process.env.VUE_APP_ASSET_API; 
const baseURLAsset = `${baseDomainAsset}`;

//START Ridham  - 31-may-2024
const approvalAPI=process.env.VUE_APP_APPROVAL_API
const baseURLApproval=`${approvalAPI}`;
//END

//START Shubham Saini  - 21-June-2024
const hiringAPI=process.env.VUE_APP_HIRING_API
const baseURLHiring=`${hiringAPI}`;
//END

//START Rahul Bhandari  - 03-Sep-2024
const timerAPI=process.env.VUE_APP_TIMER_URL
const baseURLTimer=`${timerAPI}`;
//END

//START Shubham Saini  - 21-Aug-2024
const ExpenseAPI=process.env.VUE_APP_EXPENSE_API
const baseURLExpense=`${ExpenseAPI}`;
//END



const instanceTicketing = axios.create({
    baseURL:`${ticketingApiUrl1}`
})
const instanceCRM = axios.create({
    baseURL:`${CRMApiUrl1}`
})
const instance = axios.create({
  baseURL,
});
const instanceModals = axios.create({
    baseURL,
});
const instanceCommon = axios.create({
    baseURL:baseURLCommon,
});
const instanceUser = axios.create({
    baseURL:bareUserCommon
})

const instanceAsset = axios.create({
    baseURL:baseURLAsset,
  });

const instanceApproval =axios.create({
    baseURL:baseURLApproval,
});


const instanceDocStorage = axios.create({
    baseURL:DocStorageApiUrl1,
  });


const instanceHiring = axios.create({
    baseURL:baseURLHiring,
  });

  const instanceTimer = axios.create({
    baseURL:baseURLTimer,
  });


const instanceExpense = axios.create({
    baseURL:baseURLExpense,
  });
instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/ImportContactsPost' || config.url=='/AddProduct' || config.url=='/Addnew' ?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//Use for if accept modal in backend
instanceModals.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instanceUser.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
//START - Avtar Singh - 29-may-2024 : This instance is created for Ticketing  Api which is used in Advance Setup pages (Module components)
instanceTicketing.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

instanceAsset.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8";
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
//END

//START - Shubham Saini - 29-may-2024 : This instance is created for CRM  Api which is used in Advance Setup pages (Module components)
instanceCRM.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
//END

//START Ridham-31 may 2024 :This instance is created for Approval  Api which is used in Advance Setup pages (Module components)
instanceApproval.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

//END 
//START - Sourabh Jatian - 30-may-2024 : This instance is created for DocStorage  Api which is used in Advance Setup pages (Module components)
instanceDocStorage.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
//END
//START - Shubham Saini - 21-June-2024 : This instance is created for Hiring  Api which is used in Advance Setup pages (Module components)
instanceHiring.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
//END
//START - Rahul Bhandari - 03-Sep-2024 : This instance is created for Timer  Api which is used in Initial Setup
instanceTimer.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/ImportContactsPost' || config.url=='/AddProduct'?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//END

//START - Shubham Saini - 21-Aug-2024 : This instance is created for Expense  Api which is used in Advance Setup pages (Module components)
instanceExpense.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
//END
export default {instance, instanceCommon, instanceModals, instanceUser, instanceTicketing ,instanceAsset, instanceCRM,instanceApproval,instanceDocStorage,instanceHiring,instanceTimer,instanceExpense};
