import VueI18n from 'vue-i18n'
import Vue from 'vue'
Vue.use(VueI18n)
import ID from './id'
import EN from './en'
import JP from './jp'
import ZH from './zh'

export default new VueI18n({
    locale: 'EN', 
    fallbackLocale: 'EN', 
    allowComposition: true,
    messages: {
        ID,
        EN,
        JP,
        ZH
    }, 
})

