<template>
    <div class="container-fluid" id="divjuda_menu">
      <div class="row" v-if="GetUserInfo != null">
          <div class="col-lg-4 col-xl-4">
              <breadcrumb/>
          </div>
        <div class="col-lg-4 col-xl-4"  v-if="$route.path != '/User/Dashboard'">
            <a :class="{'juda-btn judamenuCE':true, 'collapsed':checkJudamenuClose == true ?true:false} "
               data-target="#juda-menu" 
               data-toggle="collapse"
               aria-expanded="false"
               aria-controls="juda-menu" v-on:click="ToggleContextMenu($event)">
                <span id="showHideMenuParent">{{ModuleName}}</span>
                <em :class="{'fa':true }"></em>
            </a>
        </div> 
        <div class="col-lg-4 col-xl-4 text-right"  v-if="$route.path != '/User/Dashboard'">
          <span class="login-vandor d-none">
            <a
              id="alogin_as_different_user"
              href="javascript:;"
              class="text-logon"
              checkprivilege="no"
              title=""
              data-toggle-tooltip="tooltip"
              data-original-title="Login as different user">Login as different user</a>
            <a
              id="alogin_to_Org_company"
              class="text-logon"
              checkprivilege="no"
              href="/Account/LoginToOrgCompany"
              title=""
              data-toggle-tooltip="tooltip"
              data-original-title="Login to Org. Company">Login to Org. Company</a>
          </span>
          <span class="user-guide ml-3" v-if="$route.meta['userguide']">
            <a class="ancuserguide">User Guide</a>   
            <div id="collapseExampleUserGuide" class="collapse divancuserguide">
                <vue-scroll>
                                      <div id="scrollbar" class="custom-scrollbar-js" style="max-height: 300px;">
                                          <div class="user-guide-content" v-html="$t($route.meta['userguide'])">
                                        
                                          </div>
                                      </div>
                                    </vue-scroll>
                              </div>       
          </span>
        </div>
        <div class="col-lg-12" v-if="$route.path != '/User/Dashboard'">
            <div class="main-container">
                <div id="juda-menu" :class="{'collapse': true, 'show':checkJudamenuClose==true? false : true}">
                    <div class="juda-menu-container p-2">
                        <div class="menu-box" v-for="(item,index) in MenuHeaderData" :key="index">
                            <div class="heading text-center text-capitalize">{{item}}</div>
                            <ul>
                                <li v-for="(childItem,childIndex) in GetMenuData(index)" :key="childIndex">
                                    <RouterLink  v-if="getRootLink(childItem) == 'CURRENT'" class="text-capitalize" :title="$t(childItem.DISPLAY_NAME)" :id="childItem.PRIVILEGE_ID" :to="'/'+childItem.CONTROLLER_NAME+'/'+childItem.ACTION_NAME+'?reqfrom='+childItem.MODULE_MASTER_ID">
                                        {{$t(childItem.DISPLAY_NAME)}}
                                    </RouterLink>
                                    <a v-else :title="$t(childItem.DISPLAY_NAME)" class="text-capitalize" :href="getRootLink(childItem) + '/' + childItem.CONTROLLER_NAME+'/'+childItem.ACTION_NAME+'?reqhFrom='+childItem.MODULE_MASTER_ID">
                                                {{($t(childItem.DISPLAY_NAME))}}
                                            </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </template>
<script>
import $ from "jquery";
const defaultAppURL = process.env.VUE_APP_DEFAULT_APP_URL;
const fullMenuRedirectionApplied = process.env.VUE_APP_FULL_MENU_APP_REDIRECTION;
const currentAppRootURL = process.env.VUE_APP_CURRENT_SITE_ROOT_URL;
import { storeToRefs } from "pinia";
import DataService from "@/services/DataService";
import { useloggedUserInfo, moduleStore } from "../../stores/UserInfo";   
    export default {
        props:{
            modulemasterid: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                MenuData: [],
                MenuHeaderData: [],
                MaxLoop: 5,
                ModuleName: '',
                ModuleMasterId: 0,
                HideContext:false,
                ContextMenuUserConfig:false,
                checkJudamenuClose:false,
                isMenuCollaspsed:false,
                controllerName: null,
            }
        },
        setup() {
            const store = useloggedUserInfo();
            const modulestore = moduleStore()
            const { GetUserInfo } = storeToRefs(store)
            const { GetModuleId } = storeToRefs(modulestore)
            return { store,GetUserInfo, GetModuleId }
        },
        async created() {    
           // debugger        
            var reqFromStoreInitial = localStorage.getItem('reqFrom');
            this.ModuleMasterId =  reqFromStoreInitial ;
            const urlParams = new URLSearchParams(window.location.search);
                const reqFromVariations = ['reqfrom', 'Reqfrom', 'ReqFrom', 'reqFrom'];
                let reqfrom = null;
                for (let i = 0; i < reqFromVariations.length; i++) {
                    reqfrom = urlParams.get(reqFromVariations[i]);
                    if (reqfrom) {
                        break;  // Exit loop once a match is found
                    }
                }

            if(reqfrom ==null)
                {
                    var reqFromStore = localStorage.getItem('reqFrom');
                    if(reqFromStore==null)
                        {
                            const path=window.location.pathname;
                            const pathSegments = path.split('/');
                            this.controllerName = pathSegments[1];
                            await this.GetModuleMasterId(this.controllerName);
                        }
                }
            else
                {
                    localStorage.setItem('reqFrom', reqfrom);
                    this.ModuleMasterId=reqfrom;
                    
                }
            this.GetContextMenuData();
            this.HideContextmenu();
            this.HideContextMenuUserConfig();
        },
        mounted() {
            // var vm = this;
            // setTimeout(function () {
            //     vm.SetScrolbar('', null, 'custom-scrollbar-js');
            // }, 1500);
        },
        watch: {
            modulemasterid: function () { 
                this.ModuleMasterId = this.GetModuleId;
                this.GetContextMenuData();
            }
        },
        methods: {
            getRootLink: function (link) {
                var rtnValue = "";
                if (fullMenuRedirectionApplied == "true") {
                    if (link) {
                        if (link.ROOT_URL != null && link.ROOT_URL.toLowerCase() == currentAppRootURL.toLowerCase()) {
                            rtnValue = "CURRENT";
                        }
                        else if (link.ROOT_URL != null) {
                            rtnValue = link.ROOT_URL;
                        }
                        else {
                            rtnValue = defaultAppURL;
                        }
                    }
                }
                else {
                    rtnValue = "CURRENT";
                }
                return rtnValue;
            },
            // GetModuleId() {
            //     this.ModuleMasterId = this.GetModuleId;
            // },
            async ToggleContextMenu(event) {  
                // this.GetUserInfo.IsContextMenuClosed = !this.GetUserInfo.IsContextMenuClosed;
                // this.store.SetUserInfo('contextmenuclosed', this.GetUserInfo.IsContextMenuClosed ,this.GetUserInfo);
                // this.store.SetUserInfo('name', 'Radhay Chauhan');
                this.isMenuCollaspsed =true;
                $(event.target).toggleClass('collapsed');
                if ($(event.target).hasClass("collapsed")) {
                    // $("#juda-menu").removeClass("show");
                } else {
                    this.isMenuCollaspsed = false;
                    // $("#juda-menu").addClass("show");
                }
                var param='isMenuCollaspsed='+  this.isMenuCollaspsed;
                await DataService.SaveHideContextMenuConfig(param).then((response) => {
                    this.HideContextMenuUserConfig();
                });
            },
            GetMenuData(index) {
                var data = $.grep(this.MenuData, function (element) {
                    return (element.DISPLAY_ORDER == index + 1 || element.DISPLAY_ORDER == index + 6 || element.DISPLAY_ORDER == index + 11)
                });
                return data;
            },
            GetContextMenuData: async function() {
                //debugger
                this.MenuData = [];
                this.MenuHeaderData = [];                 
                var url =  `id=`
                // if(this.ModuleMasterId==0){
                //     const urlParams = new URLSearchParams(window.location.search);
                //     var reqfrom = urlParams.get('reqFrom');
                //     if(reqfrom) { 
                //         url+=reqfrom;
                //     } else {
                //         reqfrom = urlParams.get('reqhFrom');
                //         url+=reqfrom;
                //     }
                // }else{
                //     url+=this.ModuleMasterId;
                // }     
                
                url+=this.ModuleMasterId;
                await DataService.GetContextMenu(url).then((response) => {
                    if (response.data.status == "Success") {
                        this.MenuData = response.data.result.MENU_DATA;
                        var headerData = response.data.result.MENU_HEADER_DATA;
                        this.ModuleName = this.MenuData[0].MODULE_NAME;
                        this.MenuHeaderData = [];
                        for (var i = 1; i <= this.MaxLoop; i++) {
                            var headerName = "";
                            switch (i) {
                                case 1:
                                    headerName = headerData[0].HEADER1;
                                    break;
                                case 2:
                                    headerName = headerData[0].HEADER2;
                                    break;
                                case 3:
                                    headerName = headerData[0].HEADER3;
                                    break;
                                case 4:
                                    headerName = headerData[0].HEADER4;
                                    break;
                                case 5:
                                    headerName = headerData[0].HEADER5;
                                    break;
                                default:
                                    headerName = "";
                                    break;
                            }
                            this.MenuHeaderData.push(headerName);
                        }
                    }
                });
            },
            async HideContextmenu(){    
                var vm =this;
                vm.HideContextmenu='';
                var params='key=HideContextmenu'
                await DataService.GetConfigurationValueForCompanyByKey(params).then((response) => {
                    vm.HideContext =response;
                });
            },
            async HideContextMenuUserConfig(){
                var vm =this;
                await DataService.GetHideContextMenuConfig().then((response) => {                    
                    vm.ContextMenuUserConfig=response.data;
                    vm.checkJudamenuClose=vm.ContextMenuUserConfig;
                });
            },
            async GetModuleMasterId(moduleName) {
                var vm = this;
                var param='moduleName='+moduleName;
                await DataService.GetModuleMasterIdByModuleName(param).then(response => {
                    vm.ModuleMasterId = response.data;
                    // vm.GetContextMenuData();
                });
            },
        },
    }
</script>
<style>
.__bar-is-vertical,.__bar-is-horizontal{
background: rgb(146 146 146) !important;
}
</style>